import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useEffect, useState } from "react";
import ContactForm from "../../../components/Contactformulier";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";

function DataFieldsTable() {
  let [fields, setFields] = useState([]);

  useEffect(() => {
    async function getFields() {
      const response = await axios.get(
        `https://data-studio.gladior.com/fields?connectorId=linkedin_company_connector`,
        {
          headers: {
            "x-api-key": `8oSbuqEn7o7ndd1E4xAow19mdcEnOTioSJ9hA2fa`,
          },
        }
      );
      setFields(response.data);
    }

    getFields();
  }, []);

  return (
    <>
      <table className="table-auto">
        <thead className="text-white">
          <tr>
            <th className="px-4 py-2 text-left ">Name</th>
            <th className="px-4 py-2 text-left"></th>
            <th className="px-4 py-2 text-left">Type</th>
            <th className="px-4 py-2 text-left">Description</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((fieldItem, i) => {
            return (
              <tr
                className={i % 2 == 0 ? `bg-gray-800` : `bg-gray-900`}
                key={fieldItem.name}
              >
                <td className="border border-gray-600 px-4 py-2 text-white">
                  {fieldItem.name}
                </td>
                <td className="border border-gray-600 px-4 py-2 text-white">
                  {fieldItem.conceptType}
                </td>
                <td className="border border-gray-600 px-4 py-2 text-white">
                  {fieldItem.type}
                </td>
                <td className="border border-gray-600 px-4 py-2 text-white">
                  {fieldItem.description}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function LinkedinCompany({}) {
  const data = useStaticQuery(
    graphql`
      query {
        template: file(relativePath: { eq: "linkedin-template.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        data1: file(relativePath: { eq: "data-studio_linkedin.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data2: file(relativePath: { eq: "linkedin-show_account_token.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data3: file(
          relativePath: { eq: "linkedin-click_data_studio_button.png" }
        ) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data4: file(relativePath: { eq: "click_oauth_button_linkedin.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data5: file(
          relativePath: { eq: "linkedin-fill_in_account_token.png" }
        ) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data6: file(relativePath: { eq: "oauth_linkedin.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data7: file(relativePath: { eq: "linkedin-select_company.png" }) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data8: file(
          relativePath: { eq: "linkedin-all_fields_data_studio.png" }
        ) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        data9: file(
          relativePath: { eq: "linkedin-create_report_data_studio.png" }
        ) {
          childImageSharp {
            fixed(height: 500) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <>
      <SEO
        description={`Support LinkedIn Company Connector for Google Data Studio`}
        pathname={`/data-studio/linkedin-company-connector/support`}
        title={`Support | LinkedIn Company Connector`}
      />
      <Layout title={`Support`} subtitle={` LinkedIn Company Connector`}>
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              Support
            </h3>

            <p className="text-base leading-6 text-gray-300 max-w-3xl">
              <a
                className="text-gladior-pink"
                href="https://github.com/Gladior-B-V/linkedin-company-connector/issues"
              >
                Report an issue
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/voorwaarden/">
                Terms and conditions
              </a>
            </p>

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              LinkedIn Company Connector for Google Data Studio
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              With the LinkedIn company Connector you can report on the most
              important statistics of your LinkedIn company page. To use this
              connector you need an API Key. You can request this through the
              form below.
            </p>

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              LinkedIn company Template
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              The template had 1 page. This page has general data of your
              LinkedIn company page.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.template.childImageSharp.fixed}
            />

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              How to connect the data?
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              We will explain how you can connect the data to Data Studio.
            </p>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              1). Go to{" "}
              <a
                className="text-gladior-pink"
                href="https://www.gladior.com/data-studio/linkedin-company-connector/"
              >
                https://www.gladior.com/data-studio/linkedin-company-connector/
              </a>{" "}
              and get your account token.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data1.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              2). When you’ve the account token, copy this code and go to the
              link below the code.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data2.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              3). Authorize the use of the Data Studio Connector.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data3.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              4). The next step is to authorize our LinkedIn Company connector.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data4.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              5). Paste the account token in this field and click on next.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data5.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              6). Login in your account and make the connection.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data6.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              7). Choose the company page, you want to connect to the LinkedIn
              Company connector.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data7.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              8). When you’ve made the connection with Data Studio you will see
              all the possible fields of data with a description of what they
              mean.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data8.childImageSharp.fixed}
            />

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl">
              9). When you click on ‘make report’ you will connect the data to
              the template.
            </p>

            <Img
              className="mt-4 mb-8"
              fixed={data.data9.childImageSharp.fixed}
            />

            <h3 className="mt-6 text-xl text-white font-bold mb-3 font-display ">
              Metrics and Dimensions
            </h3>

            <p className="mt-6 text-base leading-6 text-gray-300 max-w-3xl mb-4">
              You can send the following metrics and dimensions to Google Data
              Studio:
            </p>
            <div className="w-full overflow-auto">
              <DataFieldsTable />
            </div>
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
}
export default LinkedinCompany;
